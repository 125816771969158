@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;700&display=swap");

* {
  box-sizing: border-box;
}
html {
  min-height: 100%; /* Look, it's not fixed anymore! */
  display: flex;
  flex-direction: column;
}
#root {
  width: 100%;
  display: flex;
  flex-grow: 1;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: radial-gradient(ellipse at center, rgba(114, 131, 165, 1) 0%, rgba(30, 47, 81, 1)) fixed;

  min-height: 100vh;
  min-height: -webkit-fill-available;
  min-width: calc(min(100vw, 600px));

  flex-grow: 1;
  display: flex;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
